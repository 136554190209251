const state = () => ({
});

const mutations = {
};

const actions = {
    saveParams: ({commit}, {searchId, params, page, sort}) => {
        window.sessionStorage.setItem(`fs_search_${searchId}`, JSON.stringify({params, page, sort}));
    }
};

const getters = {
    getSearchParams: (state) => (searchId) => {
        let savedSearch = window.sessionStorage.getItem(`fs_search_${searchId}`);
        if(savedSearch == null) {
            return null;
        }
        return JSON.parse(savedSearch).params;
    },
    getSearchPage: (state) => (searchId) => {
        let savedSearch = window.sessionStorage.getItem(`fs_search_${searchId}`);
        if(savedSearch == null) {
            return null;
        }
        return JSON.parse(savedSearch).page;
    },
    getSearchSort: (state) => (searchId) => {
        let savedSearch = window.sessionStorage.getItem(`fs_search_${searchId}`);
        if(savedSearch == null) {
            return null;
        }
        return JSON.parse(savedSearch).sort;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};