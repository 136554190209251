import BeanFactory from '../../models/BeanFactory';
import { Vue, axios } from '@master';

const state = () => ({
    showSavedItineraries: false,
    savedItineraries: {}
});

const mutations = {
    TOGGLE_SAVED_ITINERARIES: (state, show=null) => {
        state.showSavedItineraries = (show == null) ? !state.showSavedItineraries : show;
    },
    SET_ITINERARIES: (state, itineraries) => {
        state.savedItineraries = itineraries;
    },
    SAVE_ITINERARY: (state, itinerary) => {
        if(Object.keys(state.savedItineraries).length == 0) {
            state.showSavedItineraries = true;
        }
        Vue.set(state.savedItineraries, itinerary.uniqueIdentifier, itinerary);
    },
    REMOVE_ITINERARY: (state, uniqueIdentifier) => {
        Vue.delete(state.savedItineraries, uniqueIdentifier);
    },
    SET_ITINERARY_SAVING: (state, uniqueIdentifier) => {
        state.savedItineraries[uniqueIdentifier].saving = true;
    }
};

const actions = {
    toggleSavedItineraries: ({commit}, show=null) => {
        commit('TOGGLE_SAVED_ITINERARIES', show);
    },
    loadSavedItineraries: ({commit}) => {
        return new Promise((resolve, reject) => {
            axios.get('/saved')
                .then(response => {
                    const itineraries = {};
                    for(const uniqueIdentifier in response.data) {
                        response.data[uniqueIdentifier].saved = false;
                        response.data[uniqueIdentifier].saving = false;
                        response.data[uniqueIdentifier].selected = true;
                        itineraries[uniqueIdentifier] = BeanFactory.create('Itinerary', response.data[uniqueIdentifier]);
                    }
                    commit('SET_ITINERARIES', itineraries);
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
        });
    },
    saveItinerary: ({commit}, itinerary) => {
        itinerary.saving = true;
        commit('SAVE_ITINERARY', itinerary);
        return new Promise((resolve, reject) => {
            axios.post('/saved/add', {
                    searchId: itinerary.searchId,
                    itineraryId: itinerary.id
                }).then(response => {
                    itinerary.saving = false;
                    resolve(response);
                }).catch(error => {
                    itinerary.saving = false;
                    commit('REMOVE_ITINERARY', itinerary.uniqueIdentifier);
                    reject(error);
                });
        });
    },
    removeItinerary: ({commit}, uniqueIdentifier) => {
        commit('SET_ITINERARY_SAVING', uniqueIdentifier);
        return new Promise((resolve, reject) => {
            axios.post('/saved/remove', {
                    uniqueIdentifier: uniqueIdentifier
                }).then(response => {
                    commit('REMOVE_ITINERARY', uniqueIdentifier);
                    resolve(response);
                }).catch(error => {
                    commit('SET_ITINERARY_SAVING', uniqueIdentifier, false);
                    reject(error);
                });
        });
    },
    clearSavedItineraries: ({commit}, selectedItineraryIds) => {

        for(const selectedItineraryId of selectedItineraryIds) {
            commit('SET_ITINERARY_SAVING', selectedItineraryId);
        }

        return new Promise((resolve, reject) => {
            axios.post('/saved/clear', {
                    selectedItineraryIds: selectedItineraryIds
                })
                .then(response => {
                    for(const selectedItineraryId of selectedItineraryIds) {
                        commit('REMOVE_ITINERARY', selectedItineraryId);
                    }
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
        });
    }
};

const getters = {
    getShowSavedItineraries: (state) => {
        return state.showSavedItineraries;
    },
    getSavedItineraries: (state) => {
        return state.savedItineraries;
    },
    getSavedItineraryIds: (state) => {
        return Object.keys(state.savedItineraries);
    },
    getSavedItineraryCount: (state) => {
        return Object.keys(state.savedItineraries).length;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
